<template>
  <div
    :is="!prerequisite && href ? 'a' : 'div'"
    :href="href"
    :aria-label="href ? `${currency} ${title} ${content}` : false"
    :class="{
      'overview-panel--link': !!href,
      'link-reset': !!href,
      'overview-panel--prerequisite': prerequisite,
    }"
    class="overview-panel"
  >
    <SvgIcon v-if="icon" class="overview-panel__icon" :name="icon" />
    <div class="overview-panel__inner list-reset">
      <h3 v-if="title" class="heading-card overview-panel__title">
        {{ currency }}{{ title }}
      </h3>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="content"
        data-test="overview-panel-description"
        class="overview-panel__desc"
        v-html="content"
      />
      <!-- eslint-enable vue/no-v-html -->
    </div>
    <SvgIcon v-if="href" class="overview-panel__link-icon" />
  </div>
</template>

<script>
import { ToggleGroup, ToggleBlock, SvgIcon } from '@unimelb/pattern-lib-vue';

export default {
  components: { ToggleGroup, ToggleBlock, SvgIcon },
  props: {
    icon: {
      type: [String, Boolean],
      default: false,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    content: {
      type: [String, Boolean],
      default: false,
    },
    href: {
      type: [String, Boolean],
      default: false,
    },
    prerequisite: {
      type: [String, Boolean],
      default: false,
    },
    ksp: {
      type: [Boolean],
    },
    moreinfo: {
      type: [String, Object],
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="postcss">
.overview-panel {
  display: flex;
  width: 100%;
  padding: 1rem;
  background-color: var(--col-bg-light);
  align-items: center;

  &__icon {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 2rem;
    margin-right: 1rem;
    fill: var(--col-green-light-100);
  }

  &__desc {
    @mixin typography-level-7;

    margin-left: 0;
  }

  &__title {
    @mixin typography-default;

    font-weight: var(--fw-bold);
    margin-bottom: 0;
  }

  &--link {
    align-items: center;
    font-weight: var(--fw-bold);

    .overview-panel__link-icon {
      flex: none;
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0;
      margin-left: auto;
    }
  }

  &--prerequisite {
    .overview-panel__title {
      @mixin typography-level-6;

      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
      border-bottom: 1px solid var(--col-outline-alt-25);
    }
  }
}
</style>
