<template>
  <a
    :href="link"
    :aria-label="ariaLabel"
    data-test="score-panel-link"
    :class="{
      'score-panel--highlight': highlight,
      'score-panel--atar': atar,
      'score-panel--noatar': !atar,
    }"
    class="link-reset score-panel"
  >
    <dl :is="element ? element : 'dl'" class="score-panel__inner">
      <dt :is="element ? element : 'dt'" class="score-panel__title">
        <span
          class="score-panel__title-inner score-panel__icon"
          data-test="score-panel-title"
        >
          <SvgIcon v-if="!atar" name="info" />
          {{ title }}
        </span>
      </dt>
      <dd v-if="value" class="score-panel__value">
        <strong
          data-test="score-panel-value"
          class="score-panel__value-heading"
        >
          {{ value }}
        </strong>
        <span data-test="score-panel-label" class="score-panel__value-label">
          {{ label }}
        </span>
      </dd>
    </dl>
    <div class="score-panel__icon">
      <SvgIcon />
    </div>
  </a>
</template>

<script>
import { SvgIcon } from '@unimelb/pattern-lib-vue';

export default {
  components: { SvgIcon },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    atar: Boolean,
    link: {
      type: String,
      default: '/',
    },
    highlight: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    element() {
      if (!this.value || this.value === '') {
        return 'span';
      }
      return false;
    },
    ariaLabel() {
      if (this.value && this.label) {
        return `${this.title} - ${this.value} (${this.label})`;
      }
      return this.title;
    },
  },
};
</script>

<style lang="postcss">
.score-panel {
  width: 100%;
  padding: 1rem;
  background-color: var(--col-bg-light);
  border-bottom: 1px solid var(--col-outline-alt-25);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  &__icon svg {
    width: 1.25rem;
    height: 1.25rem;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    width: 100%;
  }

  &__title {
    @mixin typography-default;

    font-weight: var(--fw-bold);
    margin-bottom: 0;
    margin-right: auto;
    flex: 4;

    &-inner {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 0.75rem;
      }
    }
  }

  &__value {
    flex: 3;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    text-align: center;

    &-label {
      @mixin typography-level-7;

      margin-bottom: 0;
      font-style: italic;
    }

    &-heading {
      @mixin typography-level-3;
    }
  }

  &--noatar {
    .score-panel__inner {
      flex-direction: column;
    }
    .score-panel__title-inner {
      svg {
        color: var(--col-green-light-100);
        fill: var(--col-green-light-100);
        margin-top: 0.1rem;
      }
    }

    .score-panel__value {
      margin-right: auto;
      text-align: left;
    }

    .score-panel__value-heading {
      @mixin typography-default;
    }
  }

  &--highlight {
    &.score-panel--atar {
      .score-panel__value-heading {
        @mixin typography-level-2;
      }
    }
  }
}
</style>
